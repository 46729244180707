.dashboard-container {
    margin: 20px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.profile-container {
    display: flex;
    justify-content: space-between;
    align-items: top;
    background-color: #3e4450;
    border-radius: 10px;
    box-shadow: 2px 10px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure the container spans the full width */
}

.character-profile {
    display: flex;
    align-items: center;
    padding: 20px;
}

.character-profile img {
    border-radius: 50%;
    margin-right: 10px;
}

.character-name {
    margin: 0;
    color: #00daff;
}

.payment-status.paid {
    margin: 0;
    color: green;
}

.payment-status.unpaid {
    margin: 0;
    color: red;
}

.wallet-balance {
    margin: 0;
    color: #00daff;
}

.corporation-logo-container {
    display: flex;
    flex-direction: row; /* Align children horizontally */
    align-items: center; /* Center align items vertically */
    padding: 20px;
    margin-top: 0;
}

.corporation-logo-container img {
    border-radius: 10px; /* Rounded corners for the image */
    margin-left: 20px; /* Space between the ticker text and the image */
}

.corp-name,
.corp-member-count,
.corp-ticker {
    margin: 0;
    color: #00daff;
}

.corp-ticker {
    display: block; /* Ensure the ticker is displayed on a new line */
    margin: 0;
}
.orders-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: #3e4450;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tables-container {
    display: flex;
    justify-content: space-around; /* Adjust as needed */
    text-align: center;
}
  
.table-container {
    flex: 1; /* Makes each table container take equal width */
    margin: 0 10px; /* Add some spacing between tables */
}
  
.buy-table-container, .sell-table-container, .MuiTableCell-root {
    font-size: 10px !important;
}

.orders-header {
    text-align: center;
    color: #00daff;
}

.orders-history-container {
    margin-top: -35px;
    display: flex;
    flex-direction: column;
    background-color: #3e4450;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inactive-overlay {
    position: relative;
}
  
.inactive-subscription {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Ensure it's above the blurred tables */
    color: red
}
  
.blurred {
    filter: blur(5px);
} 

.history-chart-container {
  display: flex;
  justify-content: center; /* Centers the child containers on the main axis */
  align-items: center; /* Aligns children to the top */
  gap: 10px; /* Adds space between the containers */
  
}

.donut-chart-container {
    color: #00daff;
    display: flex;
    flex-direction: column; /* Stack the h2 and chart vertically */
    /* justify-content: center; Center the content vertically */
    align-items: center; /* Center the content horizontally */
    
  }
  

/* Responsive design adjustments if necessary */
@media (max-width: 768px) {
    .profile-container {
        flex-direction: column;
    }

    .character-profile, .corporation-logo-container {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
